"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isActive = exports.BrandConfig = void 0;
var brand_1 = require("../brand");
var sport_1 = require("./sport");
exports.BrandConfig = (0, brand_1.switchBrand)({
    UC: {
        activatedSports: [sport_1.Sport.SOCCER, sport_1.Sport.BASKETBALL],
        defaultSport: sport_1.Sport.SOCCER,
        seasonal: false,
    },
    LFP: {
        activatedSports: [sport_1.Sport.SOCCER],
        defaultSport: sport_1.Sport.SOCCER,
        seasonal: false,
    },
});
function isActive(sport) {
    return exports.BrandConfig.activatedSports.includes(sport);
}
exports.isActive = isActive;
