import React, { ReactElement, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Brand } from 'ultimate-league-common';

import { catchMissingSwitchCase } from '#technical/catchMissingSwitchCase';

import { IProgressProps, Progress } from '../../feedback';
import { IconSlot, TIconProp } from '../../icons';
import { Paragraph } from '../../typography';
import { computeColors, getLoadingColor } from './Button.config';

/* Styled Components */

const ButtonText = styled(Paragraph)`
  padding-inline: ${({ theme }) => theme.spacing(8)};
`;

type IButtonContentProps = Pick<
  IButtonProps,
  'label' | 'leadingAdornment' | 'trailingAdornment' | '$loading'
> &
  Pick<Required<IButtonProps>, 'size'> & {
    loadingColor: Exclude<IProgressProps['color'], undefined>;
  };
function ButtonContent({
  label,
  leadingAdornment,
  trailingAdornment,
  size,
  $loading,
  loadingColor,
}: IButtonContentProps) {
  if ($loading)
    return (
      <Progress
        variant="circular"
        color={loadingColor}
        size={size === 'L' ? 'L' : 'M'}
        value={undefined}
      />
    );

  return (
    <>
      {leadingAdornment && (
        <IconSlot source={leadingAdornment} size={size === 'L' ? 'L' : 'M'} />
      )}
      {label && (
        <ButtonText
          variant={size === 'L' ? 'L' : 'M'}
          bold={Brand.switchBrand({ UC: true, LFP: false })}
          ellipsis
        >
          {label}
        </ButtonText>
      )}
      {trailingAdornment && (
        <IconSlot source={trailingAdornment} size={size === 'L' ? 'L' : 'M'} />
      )}
    </>
  );
}

/* Main Component */
const DEFAULT_SIZE = 'M';

export interface IButtonProps {
  label: ReactNode;
  variant?: 'filled' | 'outlined' | 'text' | 'tonal';
  color?:
    | 'default'
    | 'dark'
    | 'accent'
    | 'error'
    | 'warning'
    | 'info'
    | 'success'
    | 'virtual';
  size?: 'XS' | 'S' | 'M' | 'L';
  leadingAdornment?: TIconProp | ReactElement;
  trailingAdornment?: TIconProp | ReactElement;
  $loading?: boolean;
  disabled?: boolean;
}

/**
 * Use `Button` to allow users to take actions, and make choices, with a single tap.
 */
export const Button = styled.button.attrs<IButtonProps>(
  ({
    label,
    leadingAdornment,
    trailingAdornment,
    size = DEFAULT_SIZE,
    variant = 'text',
    color = 'default',
    $loading,
    type = 'button',
  }) => ({
    type,
    children: (
      <ButtonContent
        label={label}
        leadingAdornment={leadingAdornment}
        trailingAdornment={trailingAdornment}
        size={size}
        loadingColor={getLoadingColor({ variant, color })}
        $loading={$loading}
      />
    ),
  })
)<IButtonProps>`
  ${({
    variant = 'text',
    color = 'default',
    size = DEFAULT_SIZE,
    disabled,
    $loading,
    theme,
  }) => css`
    // Layout
    display: grid;
    grid-auto-flow: column;
    position: relative;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 100px;

    border-width: 1px;
    border-style: solid;
    appearance: none;
    &:focus {
      outline: none;
    }

    // Typography
    white-space: nowrap;

    // User interaction
    cursor: pointer;
    pointer-events: ${disabled || $loading ? 'none' : undefined};
    user-select: none;

    transition: all 300ms;

    // Sizing
    box-sizing: border-box;
    height: ${() => {
      switch (size) {
        case 'L':
          return '50px';
        case 'M':
          return '48px';
        case 'S':
          return '40px';
        case 'XS':
          return '32px';
        default:
          throw catchMissingSwitchCase(size);
      }
    }};
    padding-inline: ${() => {
      switch (size) {
        case 'L':
          return '16px';
        case 'M':
          return '14px';
        case 'S':
          return '10px';
        case 'XS':
          return '6px';
        default:
          throw catchMissingSwitchCase(size);
      }
    }};

    ${size === 'L' &&
    css`
      // L should have the same height as M on mobile
      ${theme.breakpoints.down('md')} {
        height: 48px;
      }
    `}

    // Colors

    ${() => {
      const colorConfig = computeColors(theme.color)[color][variant];

      return css`
        color: ${colorConfig.default.text};
        background-color: ${colorConfig.default.background || 'transparent'};
        border-color: ${colorConfig.default.border || 'transparent'};

        &.hover,
        &:hover {
          transition: none;
          color: ${colorConfig.hover.text};
          border-color: ${colorConfig.hover.border};
          background-color: ${colorConfig.hover.background};
        }

        &.focus,
        &:focus {
          color: ${colorConfig.focused.text};
          border-color: ${colorConfig.focused.border};
          background-color: ${colorConfig.focused.background};
        }

        &.active,
        &:active {
          color: ${colorConfig.pressed.text};
          border-color: ${colorConfig.pressed.border};
          background-color: ${colorConfig.pressed.background};
          transform: scale(0.95);
        }

        &:disabled {
          color: ${colorConfig.disabled.text};
          border-color: ${colorConfig.disabled.border};
          background-color: ${colorConfig.disabled.background};
        }
        ${$loading &&
        css`
          border-color: ${colorConfig.loading.border || 'transparent'};
          background-color: ${colorConfig.loading.background || 'transparent'};
        `}
      `;
    }};
  `}
`;
