import React from 'react';
import styled from 'styled-components';
import { Brand } from 'ultimate-league-common';

import { catchMissingSwitchCase } from '#technical/catchMissingSwitchCase';

import { Paragraph } from '../../components';
import LFPCompactSvg from './lfp-compact.svg';
import LFPSvg from './lfp.svg';
import UCSvg from './uc.svg';

const logoVariant = Brand.switchBrand({
  UC: {
    default: <UCSvg height={24} />,
    compact: <UCSvg height={24} />,
    'extra-compact': <UCSvg height={24} />,
  },
  LFP: {
    default: <LFPSvg height={26} />,
    compact: <LFPCompactSvg height={26} />,
    'extra-compact': <LFPCompactSvg height={20} />,
  },
});

const LogoContainer = styled.div<{ color: ILogoProps['color'] }>`
  position: relative;

  color: ${({ theme, color }) => {
    switch (color) {
      case undefined:
      case 'default':
        return theme.color.primary['0'];
      case 'dark':
        return theme.color.primary['100'];
      case 'accent':
        return theme.color.accent['50'];
      default:
        throw catchMissingSwitchCase(color);
    }
  }};
`;

const Beta = styled(Paragraph)`
  position: absolute;
  display: inline-block;
  top: -8px;
  right: -16px;
`;

export interface ILogoProps {
  color?: 'default' | 'dark' | 'accent';
  variant?: 'default' | 'compact' | 'extra-compact';
  isBeta?: boolean;
}

export function Logo({
  color = 'default',
  variant = 'default',
  isBeta = false,
}: ILogoProps) {
  const showBeta = Brand.switchBrand({
    UC: isBeta,
    LFP: false,
  });

  return (
    <LogoContainer color={color}>
      {logoVariant[variant]}
      {showBeta && (
        <Beta
          variant="XS"
          bold
          uppercase
          $textColor={({ primary }) => primary['Light 40%']}
        >
          Beta
        </Beta>
      )}
    </LogoContainer>
  );
}
