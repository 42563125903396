import { SnackbarProvider } from 'notistack';
import React, { Suspense, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FeatureFlags } from 'ultimate-league-common';
import { QueryParamProvider } from 'use-query-params';
import { useEffectOnce } from 'usehooks-ts';

import { CookiePolicy } from '#common/misc';
import { getUTMParams } from '#common/referral';
import { getRoutePath } from '#common/routing';
import { useCurrentSport } from '#common/sport';
import { useUserAccount } from '#common/store';
import { analytics } from '#technical/amplitude';
import { useFlag } from '#technical/feature-flags';
import { useLanguage } from '#technical/localization';
import * as TiktokPixel from '#technical/tracking/tiktokPixel';
import { Progress, Snackbar } from '#ui/components';
import { DesignSystem } from '#ui/theme';

import { Game } from './Game';
import { Contact } from './contact';
import { FTUEGuard } from './ftue';
import { Landing } from './landing';
import { Maintenance } from './maintenance';
import { Privacy } from './privacy';
import { AuthGuard } from './routing';
import { Terms } from './terms';
import { TutorialProvider } from './tutorial';

const facebookPixelId = process.env.FACEBOOK_PIXEL_ID;
const tiktokPixelId = process.env.TIKTOK_PIXEL_ID;
const redditPixelId = process.env.REDDIT_PIXEL_ID;
const googleAdId = process.env.GOOGLE_AD_ID;

/* Styled Components */

const MainContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  -webkit-tap-highlight-color: transparent;
`;

const MaintenanceContainer = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = styled(Progress)`
  margin-block: auto;
  align-self: center;
`;

/* Main Component */

export const Application = () => {
  const { sport: currentSport } = useCurrentSport();
  const { ready: translationsReady, initializeLang } = useLanguage();
  const { account } = useUserAccount();
  const cookieConsents = CookiePolicy.useCookieConsents();
  const { pathname } = useLocation();
  const { sport } = useCurrentSport();

  useEffectOnce(() => {
    initializeLang();
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    analytics.logPageView({
      path: pathname,
      sport,
    });
    TiktokPixel.pageView();
  }, [pathname, sport]);

  useEffectOnce(() => {
    analytics.logLand({ ...getUTMParams(), sport: currentSport });
  });

  const maintenanceMode = useFlag(FeatureFlags.Features.MAINTENANCE);

  if (maintenanceMode) {
    return (
      <MaintenanceContainer>
        <Maintenance />
      </MaintenanceContainer>
    );
  }

  return (
    <>
      <Helmet>
        {/* Meta Pixel Code */}
        {cookieConsents.adPerformances && facebookPixelId && (
          <script>{`
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
               n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${facebookPixelId}');
        `}</script>
        )}
        {cookieConsents.adPerformances && facebookPixelId && (
          <img
            alt=""
            height="1"
            width="1"
            style={{ display: 'none' }}
            src={`https://www.facebook.com/tr?id=${facebookPixelId}&ev=PageView&noscript=1`}
          />
        )}
        {/* End Meta Pixel Code */}

        {/* Tiktok Pixel Code */}
        {cookieConsents.adPerformances && tiktokPixelId && (
          <script>{`
            !function (w, d, t) {
              w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
              )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

              ttq.load('${tiktokPixelId}');
            }(window, document, 'ttq');
        `}</script>
        )}
        {/* End Tiktok Pixel Code */}

        {/* Global site tag (gtag.js) - Google Ads: '<%= googleAdId %>'  */}
        {cookieConsents.adPerformances && googleAdId && (
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${googleAdId}`}
          />
        )}
        {cookieConsents.adPerformances && googleAdId && (
          <script>{`
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${googleAdId}', {'allow_enhanced_conversions': true});
        `}</script>
        )}
        {/* End Global site tag */}

        {/* Reddit Pixel Code */}
        {account && cookieConsents.adPerformances && redditPixelId && (
          <script>{`
            !function(w,d) {
              if(!w.rdt){
                var p=w.rdt=function(){
                  p.sendEvent?p.sendEvent.apply(p,arguments):p.callQueue.push(arguments)
                };
                p.callQueue=[];
                var t=d.createElement("script");
                t.src="https://www.redditstatic.com/ads/pixel.js",t.async=!0;
                var s=d.getElementsByTagName("script")[0];
                s.parentNode.insertBefore(t,s)
              }
            }(window,document);
            rdt('init','${redditPixelId}', {
              "optOut":false,
              "useDecimalCurrencyValues":true,
              "externalId":"${account.id}"
            });
          `}</script>
        )}
        {/* End Reddit Pixel Code */}

        {cookieConsents.adPerformances && (
          <img
            alt=""
            src="https://lp.adnear.net/ct.gif?a=landingpage"
            style={{ display: 'none' }}
            width="1"
            height="1"
          />
        )}
      </Helmet>
      <QueryParamProvider ReactRouterRoute={Route}>
        <DesignSystem sport={currentSport}>
          <SnackbarProvider
            Components={{
              success: Snackbar,
              default: Snackbar,
              error: Snackbar,
              info: Snackbar,
              warning: Snackbar,
            }}
          >
            <TutorialProvider>
              <MainContainer>
                {translationsReady ? (
                  <Suspense
                    fallback={
                      <Loader
                        size="XL"
                        variant="circular"
                        color="default"
                        value={undefined}
                      />
                    }
                  >
                    <Switch>
                      <Route
                        exact
                        path={[
                          getRoutePath('LANDING'),
                          getRoutePath('LOGIN'),
                          getRoutePath('REGISTER'),
                          getRoutePath('EMAIL_VERIFICATION'),
                          getRoutePath('REFERRAL'),
                        ]}
                        component={Landing}
                      />
                      <Route
                        exact
                        path={getRoutePath('TERMS')}
                        component={Terms}
                      />
                      <Route
                        exact
                        path={getRoutePath('PRIVACY')}
                        component={Privacy}
                      />
                      {!account && (
                        <Route
                          exact
                          path={getRoutePath('CONTACT')}
                          component={Contact}
                        />
                      )}
                      <AuthGuard>
                        <FTUEGuard>
                          <Route path="*" component={Game} />
                        </FTUEGuard>
                      </AuthGuard>
                    </Switch>
                    <CookiePolicy.CookieComponent />
                  </Suspense>
                ) : null}
              </MainContainer>
            </TutorialProvider>
          </SnackbarProvider>
        </DesignSystem>
      </QueryParamProvider>
    </>
  );
};
