import { Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import * as React from 'react';
import { useMemo } from 'react';
import { Brand, Payment } from 'ultimate-league-common';

import { stripe as asyncStripe } from '#technical/stripe';
import { useTheme } from '#ui/theme';

import { CheckoutForm, ICheckoutFormProps } from '../CheckoutForm';

interface ICheckoutProps extends Omit<ICheckoutFormProps, 'stripe'> {
  secret?: Payment.IClientSecret;
}

const CheckoutFormWithStripe = ({ secret, ...props }: ICheckoutProps) => {
  const stripe = useStripe();
  const elements = useElements();

  const stripeProps = useMemo(() => {
    if (!stripe || !elements || !secret) {
      return undefined;
    }

    return {
      stripe,
      elements,
      secret,
    };
  }, [stripe, elements, secret]);

  return <CheckoutForm {...props} stripe={stripeProps} />;
};

export const Checkout = ({
  secret,
  ...props
}: Omit<ICheckoutFormProps, 'stripe'> & { secret?: Payment.IClientSecret }) => {
  const theme = useTheme();

  if (!secret) {
    return <CheckoutForm {...props} />;
  }

  return (
    <Elements
      stripe={asyncStripe}
      options={{
        fonts: [
          {
            family: 'DM Sans, sans-serif',
            src: `https://${window.location.host}/fonts/dm-sans.css`,
          },
        ],
        clientSecret: secret.secret,
        appearance: {
          variables: {
            fontFamily: 'DM Sans, sans-serif',
            fontVariationSettings: "'pnum' on, 'lnum' on, 'ss03' on",
            fontSizeBase: '14px',
            colorText: Brand.switchBrand({ UC: '#FFFFFF', LFP: '#091C3E' }),
            colorBackground: theme.color.primary['90'],
            colorDanger: '#F08022',
          },
        },
      }}
    >
      <CheckoutFormWithStripe {...props} secret={secret} />
    </Elements>
  );
};
